<template>
  <section id="conditions">
    <b-overlay
      :show="isLoading"
      rounded="sm"
    >
    <!-- Conditions Table Card -->
    <b-card no-body>
      <!-- Table Top -->
      <!-- <b-row>
        <b-col cols="12" md="12">
          <div class="d-flex align-items-center justify-content-end mt-2 mr-2">
            <b-button variant="primary" to="/exercise">
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span class="text-nowrap">Add exercises</span>
            </b-button>
          </div>
        </b-col>
      </b-row>             -->
      <conditions-table
        @reload="loadConditions"
        :table-data="data.conditions"
        class="mt-2 mt-md-2"
        />
    </b-card>
    <!--/ Conditions Table Card -->
    </b-overlay>
  </section>
</template>



<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BForm,
  BOverlay,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import ConditionsTable from "./ConditionsTable.vue";

import Ripple from "vue-ripple-directive";
import Cleave from "vue-cleave-component";

export default {
  data() {
    return {
      isLoading: false,
      data: {
        conditions: [
          // {
          //   id: 1,
          //   name: "Shoulder RSI exercises",
          // },
          // {
          //   id: 2,
          //   name: "Frozen shoulder exercises",
          // },
        ],
      },
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BOverlay,
    vSelect,
    Cleave,
    ConditionsTable,
  },
  directives: {
    Ripple,
  },
  methods: {
    async loadConditions() {
      this.isLoading = true;
      const res = await this.callApi('get', '/conditions');
        this.data.conditions = res.data.result;
        this.isLoading = false;
    },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no - date - selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.dobSelected = ctx.selectedYMD;
    },
  },
  mounted() {
    this.loadConditions();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.col-form-label {
  min-height: 2.45rem;
}
</style>